import React from "react";
import Helmet from "react-helmet";

import Section from "$ui/section";
import Heading from "$ui/heading";

const CoreTeam = () => (
  <Section className="pt-32 max-w-6xl mx-auto">
    <Helmet>
      <title>TheCRO &mdash; Our Team</title>
    </Helmet>

    <Heading>Our Team</Heading>
    <article className="mt-10 space-y-10 leading-relaxed">
      <p>
        Our Team constitutes of four professionals with over a span of two
        decades of experience in resolving consumer issues legally. We are here
        with having hands full of experience in dealing with the corporate /
        government authority/ consumer commission for addressing the complaint
        on behalf of consumer
      </p>

      <p>
        We empathetically understand all your concerns however we also
        understand how crucial it is when a product is damaged or service is
        pathetic which you've paid for and get justice. Therefore, we work together
        with you to make ends meet and help you come out of some of the most
        upsetting consumer issues you ever faced.
      </p>
    </article>
  </Section>
);

export default CoreTeam;
